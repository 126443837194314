import { Vehicle } from "@/interfaces/vehicle";
import { pusherEvents } from "@/internal";
import {
  EmptyVehicle,
  NewVehicleFormFieldsConfig
} from "@/metadata/newVehicle";
import { vehiclesService } from "@/services/vehicles.service";
import { Callback, PageNavAction } from "@/types/types";
import {
  DynamicFormComponent,
  FormField,
  FormResponse
} from "helix-vue-components";
import { Component, Vue } from "vue-property-decorator";
import { Action } from "vuex-class";
import Template from "./NewVehicle.template.vue";

@Component({
  mixins: [Template],
  components: {
    DynamicFormComponent
  },
  inject: ["$changes"]
})
export default class VehicleComponent extends Vue {
  public model: Vehicle = { ...EmptyVehicle };

  public loading = false;

  public isSaving = false;

  @Action("errorRedirect", { namespace: "RouterModule" })
  protected errorRedirect!: Callback;

  @Action("setPageNav", { namespace: "PageNavModule" })
  protected setPageNav!: PageNavAction;

  protected id!: string | null;

  private fieldsConfig: FormField[] = [];

  public async save() {
    const resp: FormResponse = await (this.$refs[
      "vehicle-form"
    ] as DynamicFormComponent).submit();
    if (resp.valid) {
      this.isSaving = true;
      this.model = resp.currentModel as Vehicle;
      const response = this.id
        ? await vehiclesService.put({ ...this.model, id: this.id as string })
        : await vehiclesService.create(this.model);
      this.isSaving = false;
      if (response) {
        this.cancel();
      }
    }
  }

  public cancel() {
    this.$router.back();
  }

  public async loadVehicle() {
    this.id = this.$router.currentRoute.params.id;
    if (this.id) {
      try {
        this.loading = true;
        this.model = await vehiclesService.findById(+this.id);
        this.loading = false;
      } catch (error) {
        this.errorRedirect({
          location: { name: "vehicle-list" },
          error
        });
      }
    }
  }

  protected async mounted() {
    this.$changes.watch(pusherEvents.vehicle, this.loadVehicle);
    this.setPageNav({
      title: "vehicles.title",
      isLoading: () => this.isSaving,
      rightActions: {
        generalActions: () => [
          {
            icon: "fal fa-check",
            action: this.save,
            vuetifyProps: () => ({
              loading: this.isSaving,
              fab: true,
              small: true
            })
          },
          {
            icon: "fal fa-times",
            action: this.cancel,
            vuetifyProps: () => ({
              loading: this.isSaving,
              fab: true,
              small: true
            })
          }
        ]
      }
    });
    this.fieldsConfig = NewVehicleFormFieldsConfig;
    this.loadVehicle();
  }
}
