import { policyList } from "@/enums/permissions";
import { Vehicle } from "@/interfaces/vehicle";
import { store } from "@/internal";
import { i18n } from "@/plugins/i18n";
import HttpService from "@/services/http.service";
import { TableSuccessModalResponse } from "helix-vue-components";
import { messagesService } from "./messages.service";

const hasPermission = store.getters["PermissionsModule/hasPermission"];

class VehiclesService extends HttpService {
  protected uri: string = "support/vehicles";
  protected loadDispatcher: string = "VehicleModule/loadVehicles";

  public async get(query?: { [key: string]: any }) {
    try {
      const response = await super.get(query);
      return query && query.no_pagination ? response.data : response.data.data;
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  }

  public async create(vehicle: Vehicle) {
    try {
      const response = await super.post(vehicle);
      messagesService.renderSuccessMessage(
        i18n.t("vehicles.created_successfully").toString()
      );
      return response;
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  }

  public async put(vehicle: Vehicle) {
    try {
      const response = await super.put(vehicle, vehicle);
      messagesService.renderSuccessMessage(
        i18n.t("vehicles.updated_successfully").toString()
      );
      return response;
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  }

  public async delete(vehicle: Vehicle) {
    try {
      const response = await super.delete(vehicle);

      return response;
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  }

  public async deleteMultiple(vehicles: number[] | undefined[]) {
    try {
      const response = await super.deleteMultiple(vehicles);
      messagesService.renderSuccessMessage(
        i18n.t("drivers.updated_successfully").toString()
      );
      return response;
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  }

  public getRowActions() {
    return [
      {
        icon: "fal fa-pen",
        visibleCondition: () => hasPermission(policyList.modifyVehicles),
        action: (vehicle: Vehicle) => {
          store.dispatch("RouterModule/go", {
            name: "vehicle-edit",
            params: {
              id: vehicle.id
            }
          });
        }
      },
      {
        icon: "fal fa-trash-alt",
        visibleCondition: () => hasPermission(policyList.deleteVehicles),
        modalActions: {
          modalNumber: 1,
          modalQuestion: i18n.t("vehicles.delete_confirm").toString(),
          modalSuccessText: "yes",
          modalSuccessAction: (arg: TableSuccessModalResponse) => {
            store
              .dispatch("VehicleModule/removeVehicle", arg.item)
              .finally(arg.unselectModal);
          },
          modalCancelText: "No"
        }
      }
    ];
  }

  public getMultiActions() {
    return [
      {
        icon: "fal fa-trash-alt",
        visibleCondition: () => hasPermission(policyList.deleteVehicles),
        async action(arg: any) {
          await store.dispatch("VehicleModule/removeMultipleVehicles", arg);
        },
        multipleConfirmation: {
          text: i18n.t("table_confirmation.confirmation_text").toString(),
          title: i18n.t("table_confirmation.title").toString()
        }
      }
    ];
  }

  public getGeneralActions() {
    return [
      {
        visibleCondition: () => hasPermission(policyList.createVehicles),
        action: () => {
          store.dispatch("RouterModule/go", {
            name: "vehicle-add"
          });
        },
        icon: "fal fa-plus"
      }
    ];
  }
}

export const vehiclesService: VehiclesService = new VehiclesService();
