import { FieldType, Form } from "@/interfaces/fields";
import { Vehicle } from "@/interfaces/vehicle";
import { i18n } from "@/plugins/i18n";
import { FormField, FormFieldTypes } from "helix-vue-components";

export const NewVehicleFormFieldsConfig: FormField[] = [
  {
    label: i18n.t("name"),
    name: "name",
    component: FormFieldTypes.textField,
    options: {
      validationRules: ["required"],
      row: 1,
      flex: 4
    }
  },
  {
    label: i18n.t("vehicles.make"),
    name: "make",
    component: FormFieldTypes.textField,
    options: {
      validationRules: ["required"],
      row: 2,
      flex: 4
    }
  },
  {
    label: i18n.t("vehicles.model"),
    name: "model",
    component: FormFieldTypes.textField,
    options: {
      validationRules: ["required"],
      row: 2,
      flex: 4
    }
  },
  {
    label: i18n.t("vehicles.year"),
    name: "year",
    component: FormFieldTypes.autocomplete,
    options: {
      validationRules: ["required"],
      row: 2,
      flex: 4,
      selectOptions: () => {
        const currentYear = Number(new Date().getFullYear());
        const firstYear = 1900;
        const years = [];
        for (let year = currentYear; year >= firstYear; year--) {
          years.push(year);
        }
        return years;
      }
    }
  },
  {
    label: i18n.t("vehicles.vin_number"),
    name: "vin_number",
    component: FormFieldTypes.textField,
    options: {
      validationRules: ["required"],
      row: 3,
      flex: 4
    }
  },
  {
    label: i18n.t("vehicles.license_plate_number"),
    name: "license_plate",
    component: FormFieldTypes.textField,
    options: {
      validationRules: ["required"],
      row: 3,
      flex: 4
    }
  },
  {
    label: i18n.t("vehicles.color"),
    name: "color",
    component: FormFieldTypes.textField,
    options: {
      validationRules: ["required"],
      row: 3,
      flex: 4
    }
  }
];

export const EmptyVehicle: Vehicle = {
  name: "",
  make: "",
  color: "",
  expiration: "",
  license_plate: "",
  model: "",
  vin_number: "",
  year: null
};
